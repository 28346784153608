// React
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";

// Components
import Page from "../../page";
import InfoComponent from "../../../../components/reusable/info/info.component";
import SelectComponent from "../../../../components/reusable/select/select";
import CategoryFormatter from "../../../../components/reusable/select/categories";
import TypeFormatter from "../../../../components/reusable/select/ingredType";
import Loading from "../../../../components/reusable/loading/loading";
import Paginate from "../../../../components/reusable/pagination/paginate";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import { Label } from "../../../../components/ui/label";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
// Icons
import { FaPlusCircle, FaPencilAlt } from "react-icons/fa";
import { IoRestaurant } from "react-icons/io5";
import { MdHelpOutline } from "react-icons/md";
import Modal from "../../../../components/reusable/Modal";
import SearchBar from "../../../../components/ui/searchbar";
// Styles
// (No styles imported in this file)

const IngredCreatePage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "MENU CREATE INGRED PAGE",
  });

  const { get, post } = useApi();
  const [ingreds, setIngreds] = useState([]);
  const [recipes, setRecipes] = useState(null);
  const [ingredName, setIngredName] = useState("");
  const [ingredDescription, setIngredDescription] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { loading, setLoading } = useLoading();
  const [change, setChange] = useState(false);
  const [category, setCategory] = useState("Carne");
  const [type, setType] = useState("Natural");
  const [showInfo, setShowInfo] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [createMode, setCreateMode] = useState(true);
  const [ingredID, setIngredID] = useState(0);
  const [currentPageRecipes, setCurrentPageRecipes] = useState(1);
  const [totalPagesRecipes, setTotalPagesRecipes] = useState(1);
  const [currentPageIngreds, setCurrentPageIngreds] = useState(1);
  const [totalPagesIngreds, setTotalPagesIngreds] = useState(1);
  const [search, setSearch] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [create, setCreate] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();

    const getGraph = async (url) => {
      try {
        const response = await get(url, {
          signal: controller.signal,
        });
        setIngreds(response.data.results.data);
        setTotalPagesIngreds(response.data.results.total_pages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    const searchParams = new URLSearchParams();
    searchParams.append("page", currentPageIngreds.toString());
    if (searchItem) {
      searchParams.append("search", searchItem);
    }

    const apiUrl = `ingred_list?${searchParams.toString()}`;

    getGraph(apiUrl);

    return () => {
      controller.abort();
    };
  }, [currentPageIngreds, searchItem]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await get(`recipe_list?page=${currentPageRecipes}`);
        setRecipes(response.data);
        setTotalPagesRecipes(response.data["results"].total_pages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [currentPageRecipes, change]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      ingredName === "" ||
      ingredDescription === "" ||
      category === "" ||
      type === ""
    ) {
      Toaster.show("Por favor preencha os campos.", "error");
      return;
    }
    setShowModal(false);

    const ingredToCreate = {
      ingr_id: createMode ? 0 : ingredID,
      ingr_name: ingredName,
      ingr_descrip: ingredDescription,
      ingr_category: category,
      ingr_degree_process: type,
      ingr_recipe:
        createMode && type === "Receita" ? selectedRecipe.recipe_id : null,
      ingr_order: null,
    };
    try {
      const response = await post(
        `/ingred_create_or_update/`,
        JSON.stringify(ingredToCreate)
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show(
          `Ingrediente ${createMode ? "criado" : "editado"} com sucesso`,
          "success"
        );
        setChange(!change);
      } else if (response.status === 405) {
        Toaster.show(
          `Receita selecionada não pode ser associada a um ingrediente, pois já está associada a outro ingrediente.`,
          "error"
        );
        setLoading(false);
      } else {
        Toaster.show(
          `Erro ao ${createMode ? "criar" : "editar"} ingrediente.`,
          "error"
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show(
        `Erro ao ${createMode ? "criar" : "editar"} ingrediente.`,
        "error"
      );
      setLoading(false);
    }
    setCategory("Carne");
    setType("Natural");
    setSelectedRecipe("");
    setIngredName("");
    setIngredDescription("");
  };

  const darkMode = useDarkMode();

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("pt-PT", {
      hour: "numeric",
      minute: "numeric",
    });
    return formattedDate;
  }

  const handleHover = () => {
    setShowInfo(true);
  };

  const handleMouseLeave = () => {
    setShowInfo(false);
  };


  useEffect(() => {
    setCategory("Carne");
    setType("Natural");
    setSelectedRecipe("");
    setIngredName("");
    setIngredDescription("");
  }, [create]);

  return (
    <Page title={"Gerir Ingredientes"} loading={loading}>
      {tokenVerified ? (
        <div className="h-screen">
          <div
            style={{ maxWidth: "1000px", paddingBottom: "50px" }}
            className="m-auto text-black"
          >
            <div className="flex items-center justify-between mt-10">
              <p
                style={{ color: darkMode ? "white" : "black" }}
                className="text-4xl font-semibold text-blue-dark"
              >
                Criar novos ingredientes
              </p>
              <div>
                <Button
                  onClick={() => {
                    setCreateMode(true);
                    setShowModal(true);
                    setCreate(!create);
                  }}
                >
                  + Novo Ingrediente
                </Button>
              </div>
            </div>
            <div>
              <InfoComponent text="">
                <div>
                  <p>
                    Adicione um novo ingrediente à sua lista de ingredientes que
                    poderá, posteriormente, ser associado a uma receita.
                  </p>
                  <p>
                    Para os ingredientes do tipo receitas poderá clicar sobre
                    receita para verificar o nome da mesma.
                  </p>
                </div>
              </InfoComponent>
              <div className="py-4">
                <SearchBar
                  search={search}
                  setSearch={setSearch}
                  setFetchSearch={setSearchItem}

                />
              </div>

              {ingreds.length > 0 ? (
                <div>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>
                          Nome
                        </TableHead>
                        <TableHead>
                          Classe
                        </TableHead>
                        <TableHead>
                          Descrição
                        </TableHead>
                        <TableHead>
                          Tipo
                        </TableHead>
                        <TableHead>
                          Data Criação
                        </TableHead>
                        <TableHead>
                          Associar SKU
                        </TableHead>
                        <TableHead>
                          Editar
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody
                      style={{ backgroundColor: darkMode ? "#262853" : "" }}
                      className="bg-white divide-y divide-gray-200"
                    >
                      {ingreds.map((ingredient) => (
                        <TableRow key={ingredient.ingr_id}>
                          <TableCell>
                            {ingredient.ingr_name}
                          </TableCell>
                          <TableCell>
                            {ingredient.ingr_category}
                          </TableCell>
                          <TableCell>
                            {ingredient.ingr_descrip}
                          </TableCell>
                          <TableCell>
                            {ingredient.ingr_degree_process == null
                              ? ""
                              : ingredient.ingr_degree_process
                                .charAt(0)
                                .toUpperCase() +
                              ingredient.ingr_degree_process
                                .slice(1)
                                .toLowerCase()}
                            {ingredient.recipe
                              ? " - " + ingredient.recipe.recipe_name
                              : ""}
                          </TableCell>
                          <TableCell>
                            {formatDate(ingredient.ztimestamp)}
                          </TableCell>
                          <TableCell>

                            <FaPlusCircle
                              title="Associar SKU"
                              style={{ color: darkMode ? "white" : "black" }}
                              className="text-black text-2xl hover:scale-125 transition-transform duration-300"
                              onClick={() => navigate(`/app/menu/ingredient?id=${ingredient.ingr_id}`, { state: { id: ingredient.ingr_id, name: ingredient.ingr_name } })}
                            />
                          </TableCell>
                          <TableCell>
                            <FaPencilAlt
                              title="Editar"
                              onClick={() => {
                                setCreateMode(false);
                                setIngredID(ingredient.ingr_id);
                                setCategory(ingredient.ingr_class);
                                setIngredName(ingredient.ingr_name);
                                setIngredDescription(ingredient.ingr_descrip);
                                setType(
                                  ingredient.ingr_degree_process == null
                                    ? ""
                                    : ingredient.ingr_degree_process
                                      .charAt(0)
                                      .toUpperCase() +
                                    ingredient.ingr_degree_process
                                      .slice(1)
                                      .toLowerCase()
                                );
                                setShowModal(true);
                              }}
                              style={{
                                color: darkMode ? "white" : "black",
                                cursor: "pointer",
                              }}
                              className="text-black text-2xl hover:scale-125 transition-transform duration-300"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <div
                  style={{ color: darkMode ? "white" : "black" }}
                  className="text-center text-2xl mt-10"
                >
                  Sem ingredientes que contenham {search} no nome
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <Paginate
                currentPage={currentPageIngreds}
                setCurrentPage={setCurrentPageIngreds}
                totalPages={totalPagesIngreds}
                loading={!ingreds}
              />
            </div>

          </div>
          <Modal isOpen={showModal} setIsOpen={setShowModal}>
            <form
            
              onSubmit={(e) => handleSubmit(e)}
              className="bg-white p-10 shadow-md h-[50vh]"
            >
              <div
                style={{ color: darkMode ? "white" : "black" }}
                className="text-2xl mb-10 font-semibold text-blue-dark text-center"
              >
                {createMode ? "Novo Ingrediente" : "Editar Ingrediente"}
              </div>
              <div className="mb-4 flex">
                <div className="w-1/2 pr-2">
                  <Label
                    style={{ color: darkMode ? "white" : "black" }}
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Nome
                  </Label>
                  <input
                    style={{ backgroundColor: darkMode ? "#262853" : "" }}
                    className="w-full bg-gray-200 border rounded py-2 px-3"
                    type="text"
                    placeholder="Nome do Ingrediente"
                    value={ingredName}
                    onChange={(e) => setIngredName(e.target.value)}
                  />
                </div>
                <div className="w-1/2 pl-2">
                  <Label
                    style={{ color: darkMode ? "white" : "black" }}
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Categoria
                  </Label>
                  <SelectComponent
                    formatter={CategoryFormatter}
                    darkMode={darkMode}
                    onChange={setCategory}
                    value={category}
                  />
                </div>
              </div>
              <div className="mb-4">
                <Label
                  style={{ color: darkMode ? "white" : "black" }}
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Descrição
                </Label>
                <textarea
                  style={{ backgroundColor: darkMode ? "#262853" : "" }}
                  className="w-full bg-gray-200 border rounded py-2 px-3"
                  value={ingredDescription}
                  placeholder="Descrição do Ingrediente"
                  onChange={(e) => setIngredDescription(e.target.value)}
                />
              </div>
              <div className="mb-4 flex items-center">
                <div className="w-1/6 pr-0">
                  <Label
                    style={{ color: darkMode ? "white" : "black" }}
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Tipo
                    {showInfo && (
                      <div
                        style={{
                          position: "absolute",
                          left: "10%",
                          right: "10%",
                        }}
                      >
                        <InfoComponent text="">
                          <div>
                            <p>
                              Relativamente ao tipo de ingrediente, pretende-se
                              classificar o ingrediente pelo seu grau de
                              processamento:
                            </p>
                            <p>
                              Natural - ingrediente usado como produto natural
                            </p>
                            <p>
                              Processados - ingrediente adquirido com
                              processamento industrial
                            </p>
                            <p>
                              Receita - ingrediente processado internamente pelo
                              restaurante e que tem receita associada no
                              sistema(Exemplo: molho específico utilizado numa
                              outra receita)
                            </p>
                          </div>
                        </InfoComponent>
                      </div>
                    )}
                  </Label>
                </div>
                <div className="w-full pr-2">
                  <MdHelpOutline
                    title="Ajuda"
                    style={{
                      color: darkMode ? "white" : "black",
                      cursor: "pointer",
                    }}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleMouseLeave}
                    className="text-black text-2xl hover:scale-150 transition-transform duration-300"
                  />
                </div>
                <SelectComponent
                  formatter={TypeFormatter}
                  darkMode={darkMode}
                  onChange={setType}
                  value={type}
                />
              </div>

              {type === "Receita" ? (
                <div>
                  <Label
                    style={{ color: darkMode ? "white" : "black" }}
                    className="block text-gray-700 text-sm font-bold mb-2 mt-10"
                  >
                    Selecione uma receita
                  </Label>
                  <InfoComponent text="">
                    <p>
                      Pode associar uma receita que represente o seu
                      ingrediente. Por exemplo, pode definir o ingrediente
                      salada, que tem a sua própria receita definida no sistema.
                    </p>
                  </InfoComponent>
                  {recipes && (
                    <>
                      <div
                        style={{ paddingTop: "10px", paddingBottom: "50px" }}
                      >
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHead>
                                Nome
                              </TableHead>
                              <TableHead>
                                Select
                              </TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {recipes["results"]["recipes"].map((recipe) => (
                              <TableRow key={recipe.recipe_id}>
                                <TableCell>
                                  {recipe.recipe_name}
                                </TableCell>
                                <TableCell>
                                  <input
                                    type="checkbox"
                                    checked={recipe === selectedRecipe}
                                    onChange={() => setSelectedRecipe(recipe)}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingBottom: "50px",
                        }}
                      >
                        <Paginate
                          currentPage={currentPageRecipes}
                          setCurrentPage={setCurrentPageRecipes}
                          totalPages={totalPagesRecipes}
                          loading={!recipes}
                        />
                      </div>
                      <Loading isOpen={loading} />
                    </>
                  )}
                </div>
              ) : null}
              <Button
                type="submit">
                Guardar Ingrediente
              </Button>
            </form>
          </Modal>
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default IngredCreatePage;
