// React
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";
import { useCat } from "../../../../utils/contexts/CategoryContext";

// Components
import Page from "../../page";
import BackComponent from "../../../../components/reusable/goBack/go-back-component";
import InfoComponent from "../../../../components/reusable/info/info.component";
import Loading from "../../../../components/reusable/loading/loading";
import Toaster from "../../../../components/reusable/Toaster";
import SkuCreate from "../sku/sku-create";
import { Button } from "../../../../components/ui/button";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
// Icons
import { IoRestaurant } from "react-icons/io5";
import { FaTrash } from "react-icons/fa";
import Modal from "../../../../components/reusable/Modal";

const IngredDetailPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "MENU SKUS PAGE",
  });

  const location = useLocation();
  const { id, name } = location.state || {};
  const axiosPrivate = useAxiosPrivate();
  const [selectedSKU, setselectedSKU] = useState("");
  const [refProd, setRefProd] = useState(Object);

  const [data, setData] = useState([]);
  const { loading, setLoading } = useLoading();
  const [modalCreate, setModalVisibleCreate] = useState(false);
  const [changedList, setChangedList] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(-1);

  const { Categories } = useCat();
  const [selectedCategories, setSelectedCategories] = useState([]);
  // const handleCategoryChange = (sku_id, event) => {
  //   setSelectedCategories(prevState => {
  //     const existingItemIndex = prevState.findIndex(item => item.id === sku_id);

  //     if (existingItemIndex !== -1) {
  //       // If the item exists, replace its category with the new one
  //       const updatedItem = { ...prevState[existingItemIndex], category: event.target.value };
  //       return prevState.map((item, index) => index === existingItemIndex ? updatedItem : item);
  //     } else {
  //       // If the item doesn't exist, add a new one
  //       const newCategory = { id: sku_id, category: event.target.value };
  //       return [...prevState, newCategory];
  //     }
  //   });
  // };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getIngreds = async (endpoint, ingrId = {}) => {
      setLoading(true);
      try {
        // Make the API request using axios
        const response = await axiosPrivate.get(endpoint, {
          params: { ingr_id: ingrId },
          signal: controller.signal,
        });
        console.log(response.data);
        setData(response.data);

        const refProd = response.data["assigns"].find(
          (item: { ass_state: string }) => item.ass_state === "REF"
        );
        refProd ? setRefProd(refProd.sku) : setRefProd("");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    getIngreds("list_ingr_skus", id);

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [changedList]);

  const deleteSku = async (sku_assign_id: Number) => {
    setLoading(true);
    const body = {
      sku_id: sku_assign_id,
      ingr_id: id,
    };
    const response = await axiosPrivate.post(
      `/sku_assign_delete/`,
      JSON.stringify(body)
    );

    if (response.status === 200) {
      Toaster.show("Associação apagada com sucesso.", "success");
      setChangedList(!changedList);
    } else {
      Toaster.show("Erro ao apagar associação.", "error");
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("pt-PT", {
      hour: "numeric",
      minute: "numeric",
    });
    return formattedDate;
  }

  const handleRef = async () => {
    if (selectedSKU === "") {
      Toaster.show("Selecione primeiro um item.", "error");
      return;
    }
    const body = {
      ingr_id: id,
      sku_id: selectedSKU,
      sku_id_ref: refProd,
    };
    try {
      const response = await axiosPrivate.post(
        `/make_ref/`,
        JSON.stringify(body)
      );

      if (response.status === 200) {
        Toaster.show("Produto selecionado feito referência", "success");
        setChangedList(!changedList);
      } else {
        Toaster.show("Ocorreu um erro, tente mais tarde.", "error");
      }
    } catch (error) {
      Toaster.show("Ocorreu um erro, tente mais tarde.", "error");
    }
  };

  // const handleCategory = async () => {
  //   if (selectedCategories.length === 0) {
  //     Toaster.show("Mude primeiro uma categoria.")
  //     return;
  //   }
  //   const body = {
  //     changes: selectedCategories
  //   };
  //   try {
  //     const response = await axiosPrivate.post(`/sku_change_category/`,
  //       JSON.stringify(body),
  //     );

  //     if (response.status === 201) {
  //       Toaster.show("Categoria(s) alterada(s) com sucesso.")
  //       setChangedList(!changedList);
  //     }
  //     else {
  //       Toaster.show("Ocorreu um erro, tente mais tarde.")
  //     }
  //   } catch (error) {
  //     Toaster.show("Ocorreu um erro, tente mais tarde.")
  //   }

  // };

  const handleCheckboxChange = (sku_id) => {
    setselectedSKU((prevselectedSKU) => {
      if (prevselectedSKU === sku_id) {
        return ""; // deselect the sku if it's already selected
      } else {
        return sku_id;
      }
    });
  };

  const getValue = (item) => {
    const selectedCategory = selectedCategories.find(
      (categoryItem) => categoryItem.id === item.sku_id
    );
    return selectedCategory ? selectedCategory.category : item.sku_category;
  };

  const darkMode = useDarkMode();

  return (
    <Page title={"SKUS"} loading={loading}>
      {tokenVerified ? (
        <div>
          {!loading && data && (
            <div className="w-[60%] mx-auto text-black">
              <BackComponent></BackComponent>
              <div className="flex items-center justify-between">
                <p
                  style={{ color: darkMode ? "white" : "black" }}
                  className="text-4xl font-semibold mt-10 text-blue-dark"
                >
                  {data["ingred"][0].ingr_name} {data["ingred"][0].ingr_class}
                </p>
                <div className="flex flex-col gap-8 pt-10">
                  <Button
                    onClick={() => setModalVisibleCreate(true)}
                    style={{ background: darkMode ? "#422655" : "" }}
                  >
                    + Associar Sku
                  </Button>
                  <Button
                    onClick={() => handleRef()}
                    style={{ background: darkMode ? "#422655" : "" }}
                  >
                    Tornar Referência
                  </Button>
                </div>
              </div>

              <InfoComponent text="">
                <div>
                  <p>
                    Ingrediente criado a{" "}
                    {formatDate(data["ingred"][0].ztimestamp)}
                  </p>
                  <br></br>
                  <p>
                    Deverá indicar um SKU de preferência/referência entre a
                    seguinte lista.Selecione o SKU de referência desejado e
                    precione "Tornar Referência".
                  </p>
                </div>
              </InfoComponent>

              {data["skus"].length > 0 ? (
                <Table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
                  <TableHeader className="bg-white">
                    <TableRow>
                      <TableHead>
                        Nome
                      </TableHead>
                      <TableHead>
                        Refência
                      </TableHead>

                      <TableHead>
                        Categoria
                      </TableHead>
                      <TableHead>
                        Quantidade
                      </TableHead>
                      <TableHead>
                        Actions
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {data["skus"].map((item: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell>
                          {item.sku_prod_name}
                        </TableCell>
                        <TableCell>
                          <input
                            type="checkbox"
                            checked={
                              selectedSKU === ""
                                ? refProd === item.sku_id
                                : selectedSKU === item.sku_id
                            }
                            onChange={() => handleCheckboxChange(item.sku_id)}
                          />
                        </TableCell>
                        <TableCell>
                          {/* <select value={getValue(item)} className="p-2 rounded-xl bg-transparent outline-none" onChange={(event) => handleCategoryChange(item.sku_id, event)}>
                    {Categories.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))}
                  </select> */}
                          {item.sku_category}
                        </TableCell>
                        <TableCell>
                          {item.sku_packing} {item.sku_price_type}
                        </TableCell>
                        <TableCell>

                          <FaTrash
                            title="Eliminar"
                            className="text-red-300 hover:text-red-500 text-xl cursor-pointer hover:scale-125 transition-transform duration-30"
                            onClick={() =>
                              Toaster.showWithHandler(
                                `Estará a eliminar o item ${item.sku_prod_name}. Deseja continuar?`,
                                () => deleteSku(item.sku_id),
                                { duration: Infinity }
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <div
                  style={{ color: darkMode ? "white" : "black" }}
                  className="text-center text-2xl text-blue-dark"
                >
                  Não existem ingredientes associados a esta receita.
                </div>
              )}
            </div>
          )}
          <Modal isOpen={modalCreate} setIsOpen={setModalVisibleCreate}>
            <div className="w-3/5 h-3/4">
              <div
                className="p-8"
                style={{ overflowY: "auto", overflowX: "hidden" }}
              >
                <SkuCreate
                  ingrName={name}
                  modalCloser={setModalVisibleCreate}
                  setChange={setChangedList}
                  change={changedList}
                />
              </div>
            </div>
          </Modal>
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default IngredDetailPage;
