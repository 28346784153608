// React
import { useState, useEffect, SetStateAction } from "react";
import { Link, useNavigate } from "react-router-dom";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";
import { useLoading } from "../../../../utils/contexts/LoadingContext";

// Components
import Page from "../../page";
import RecipeForm from "./RecipeCreateModal";
import AssignProd from "./RecipeAssignProdModal";
import InfoComponent from "../../../../components/reusable/info/info.component";
import Loading from "../../../../components/reusable/loading/loading";
import Paginate from "../../../../components/reusable/pagination/paginate";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";

// Icons
import { IoAdd, IoRestaurant } from "react-icons/io5";
import { FaTrash, FaPencilAlt, FaPlus } from "react-icons/fa";
import { BiFoodMenu } from "react-icons/bi";
import Modal from "../../../../components/reusable/Modal";
import IngredCreateModal from "../ingredients/IngredCreateModal";
import { IoMdAddCircle } from "react-icons/io";

const RecipeListPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "RECIPES PAGE",
  });

  const { get, post } = useApi();
  const [data, setData] = useState(null);
  const { loading, setLoading } = useLoading();
  const [modalCreate, setModalVisibleCreate] = useState(false);
  const [modalEdit, setModalVisibleEdit] = useState(false);
  const [modalAddProd, setModalAddProd] = useState(false);
  const [editable, setEditable] = useState(0);
  const [changedList, setChangedList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [modalAssIngre, setModalVisibleAssIngr] = useState(false);
  const [recipe, setRecipe] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await get(`recipe_list?page=${currentPage}`);
        console.log(response);
        setData(response.data);
        setTotalPages(response.data["results"].total_pages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [currentPage, changedList]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("pt-PT", {
      hour: "numeric",
      minute: "numeric",
    });
    return formattedDate;
  }

  const deleteRecipe = async (recipe_id: string) => {
    try {
      const response = await post(
        `/recipe_delete/`,
        JSON.stringify({ recipe_id: recipe_id })
      );

      if (response.status === 200) {
        Toaster.show("Receita apagada com sucesso.", "success");
        setLoading(true);
        setChangedList(!changedList);
      } else {
        Toaster.show("Erro ao apagar receita.", "error");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      Toaster.show(
        "Erro ao apagar receita. Tente novamente mais tarde.",
        "error"
      );
    }
  };
  const darkMode = useDarkMode();

  // Function to handle dialog close
  const handleDialogClose = () => {
    setModalVisibleCreate(false);
    setModalVisibleEdit(false);
    setModalAddProd(false);
  };

  return (
    <Page title={"Gerir Receitas"} loading={loading}>
      {tokenVerified ? (
        <div className="h-screen">
          {data && (
            <div className="w-[80%] m-auto text-black">
              {/* Header Section */}
              <div className="flex items-center justify-between mt-10">
                <CardHeader>
                  <CardTitle className="text-4xl font-semibold text-blue-dark">
                    Receitas
                  </CardTitle>
                  <CardDescription>
                    Consulte e edite as receitas do seu restaurante.
                  </CardDescription>
                </CardHeader>
                <Button onClick={() => setModalVisibleCreate(true)}>
                  <IoAdd size={16} className="mr-2" /> Receita
                </Button>

              </div>

              {/* Info Section */}
              <InfoComponent text="">
                <p>
                  Lista de receitas do restaurante com classe correspondente. A
                  cada receita deve ser associado um produto da carta, deste
                  modo a aplicação monitoriza o desempanho de vendas da receita.
                </p>
              </InfoComponent>

              {/* Recipe Table */}
              {data && data["results"]["recipes"].length > 0 ? (
                <Table>
                  <TableHeader className="bg-white">
                    <TableRow>
                      <TableHead>Nome</TableHead>
                      <TableHead>Classe</TableHead>
                      <TableHead>Produto da carta</TableHead>
                      <TableHead>Estado</TableHead>
                      <TableHead>Date</TableHead>
                      <TableHead>Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {data["results"]["recipes"].map((item, index) => (
                      <TableRow key={index} className="bg-white">
                        <TableCell>

                          {item.recipe_name}
                        </TableCell>
                        <TableCell>{item.recipe_class}</TableCell>
                        <TableCell>
                          {!data["results"]["prods"][index]?.rest_prod_desc ? (
                            <Dialog>
                              <DialogTrigger asChild>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setModalAddProd(true);
                                    setEditable(item.recipe_id);
                                  }}
                                  className="hover:bg-gray-200 bg-gray-100 text-gray-600 text-xs transition-transform duration-300"
                                >
                                  {"Adicionar produto..."}
                                </Button>
                              </DialogTrigger>
                              <DialogContent className="w-[80vw] max-w-[1000px] h-[80vh] max-h-[800px] overflow-y-auto">
                                <DialogHeader>
                                  <DialogTitle>Associar Produtos</DialogTitle>
                                  <AssignProd
                                    recipe_id={editable}
                                    modalCloser={handleDialogClose}
                                    change={changedList}
                                    setChange={setChangedList}
                                  />
                                </DialogHeader>
                              </DialogContent>
                            </Dialog>
                          ) : (
                            data["results"]["prods"][index]?.rest_prod_desc
                          )}
                        </TableCell>
                        <TableCell
                          className={darkMode ? "text-white" : "text-black"}
                        >
                          {item.recipe_status}
                        </TableCell>
                        <TableCell
                          className={darkMode ? "text-white" : "text-black"}
                        >
                          {formatDate(item.ztimestamp)}
                        </TableCell>
                        <TableCell>
                          <div className="flex space-x-4">
                            <IoMdAddCircle
                              title="Associar Ingrediente"
                              className={`text-blue-dark text-xl ${darkMode ? "text-white" : "text-black"
                                } cursor-pointer`}
                              onClick={() => navigate(`/app/menu/recipe?id=${item.recipe_id}`)}

                            />
                            <BiFoodMenu
                              title="Ver Ficha Tecnica"
                              className={`text-blue-dark text-xl ${darkMode ? "text-white" : "text-black"
                                } cursor-pointer`}
                              onClick={() => navigate('/app/menu/recipe/tec', { state: { recipeId: item.recipe_id } })}
                            />
                            <FaPencilAlt
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditable(item.recipe_id);
                                setModalVisibleEdit(true);
                              }}
                              title="Editar"
                              className="text-gray-600 hover:text-gray-800 text-xl cursor-pointer"
                            />
                            <FaTrash
                              onClick={() =>
                                Toaster.showWithHandler(
                                  `Estará a eliminar o item ${item.recipe_name}. Deseja continuar?`,
                                  () => deleteRecipe(item.recipe_id),
                                  { duration: Infinity }
                                )
                              }
                              title="Eliminar"
                              className="text-red-300 hover:text-red-500 text-xl cursor-pointer"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <div className="text-center text-2xl text-blue-dark h-screen">
                  Não existem Receitas.
                </div>
              )}
            </div>
          )}

          {/* Pagination */}
          <div className="flex justify-center pb-8 pt-4">
            <Paginate
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              loading={!data}
            />
          </div>


          <Modal isOpen={modalCreate} setIsOpen={setModalVisibleCreate}>
            <div >
              {" "}
              {/* Increased width to 80vw and set a max width */}
              <h2
                style={{
                  color: darkMode ? "white" : "black",
                  paddingBottom: "20px",
                  textAlign: "center",
                }}
              >
                Criar Receita
              </h2>
              <div className="pt-5">
                <RecipeForm
                  modalCloser={handleDialogClose}
                  change={changedList}
                  setChange={setChangedList}
                />
              </div>
            </div>
          </Modal>


          <Modal isOpen={modalEdit} setIsOpen={setModalVisibleEdit}>
            <div >
              {" "}
              {/* Increased width to 80vw and set a max width */}
              <h2
                style={{
                  color: darkMode ? "white" : "black",
                  paddingBottom: "20px",
                  textAlign: "center",
                }}
              >
                Editar Receita
              </h2>
              <div className="pt-5">
                <RecipeForm
                  recipe_id={editable}
                  modalCloser={handleDialogClose}
                  change={changedList}
                  setChange={setChangedList}
                />
              </div>
            </div>
          </Modal>
          {/* <Modal isOpen={modalAssIngre} setIsOpen={setModalVisibleAssIngr}>
            <div style={{ overflowY: "auto", overflowX: "hidden" }}>
              {recipe && (
                <IngredCreateModal
                  modalCloser={setModalVisibleAssIngr}
                  setChange={setChangedList}
                  change={changedList}
                  recipeId={recipe.recipe_id}
                  recipeName={recipe.recipe_name}
                />
              )}
            </div>
          </Modal> */}


          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default RecipeListPage;
