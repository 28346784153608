// React
import { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";

// Icons
import { IoPricetag } from "react-icons/io5";

// Contexts
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../Profile/usedarkmode";
import { useLoading } from "../../../utils/contexts/LoadingContext";

// Components
import MyTable from "../../../components/reusable/table/table-component";
import SelectComponent from "../../../components/reusable/select/select";
import BackComponent from "../../../components/reusable/goBack/go-back-component";
import Loading from "../../../components/reusable/loading/loading";
import NWeeksFormatter from "../../../components/reusable/select/nweeks";
import Page from "../page";
import Toaster from "../../../components/reusable/Toaster";
import SearchBar from "../../../components/ui/searchbar";
// Constants
const INITIAL_WEEKS = 4;

const columns = [
  { headerName: "Ingrediente", field: "sku_prod_name" },
  { headerName: "Delta", field: "delta" },
  { headerName: "Delta pct", field: "delta_pct" },
  { headerName: "Média", field: "price_avg" },
  { headerName: "Preço inicial", field: "price_ini" },
  { headerName: "Preço final", field: "price_fim" },
  { headerName: "Preço máximo", field: "price_max" },
  { headerName: "Preço mínimo", field: "price_min" },
];

const FoodCostIngredients = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const { get } = useApi();
  const location = useLocation();
  const darkMode = useDarkMode();

  const item = new URLSearchParams(location.search).get("item");

  const [nweeks, setNweeks] = useState(INITIAL_WEEKS);
  const { loading, setLoading } = useLoading();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchItem, setSearchItem] = useState("");

  const fetchSkuData = useCallback(async () => {
    setLoading(true);
    try {
      const url = `dash3_list_sku/?nweeks=${nweeks}&ingr=${item}`;
      const response = await get(url);
      setRows(response.data);
    } catch (error) {
      Toaster.show("Erro ao carregar dados de SKU", "error");
      console.error("Error fetching SKU data:", error);
    } finally {
      setLoading(false);
    }
  }, [nweeks, item]);

  useEffect(() => {
    fetchSkuData();
  }, [fetchSkuData]);

  const filteredSku = rows.filter((recipe) =>
    recipe.sku_prod_name.toLowerCase().includes(searchItem.toLowerCase())
  );

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "FOODCOST INGREDIENTS PAGE",
  });

  return (
    <Page
      title={"Custo das Receitas e Ingredientes"}
      loading={loading}
    >
      {tokenVerified ? (
        <div className="ion-padding">
          <div>
            <div
              className="padding"
              style={{ paddingBottom: "20px", paddingTop: "20px" }}
            >
              <div>
                <p className="welcome">
                  Evolução dos preços de aquisição de bens alimentares
                </p>
              </div>
              <BackComponent />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <SelectComponent
                  formatter={NWeeksFormatter}
                  darkMode={darkMode}
                  onChange={setNweeks}
                />
              </div>
            </div>
            <div style={{ paddingBottom: "20px", paddingTop: "50px" }}>
              <div className="homePageText">
                <div
                  style={{ color: darkMode ? "#f4f4f4" : "black" }}
                  className="seeNews"
                >
                  Visão de produtos
                </div>
              </div>
            </div>
          </div>
          <div className="padding">
            <div className="search">
            <SearchBar
              search={search}
              setSearch={setSearch} 
              setFetchSearch={setSearchItem}
             />
            </div>
            <MyTable
              rowData={filteredSku}
              columnDefs={columns}
              title={item}
              darkMode={darkMode}
            />
          </div>
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
<></>
      )}
    </Page>
  );
};
export default FoodCostIngredients;
