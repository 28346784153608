// React
import { useEffect, useState } from "react";

// contexts
import axios from "axios";
import useDarkMode from "../../../Profile/usedarkmode";
import { DarkModeProvider } from "../../../../utils/contexts/theme.context";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";

// components
import Loading from "../../../../components/reusable/loading/loading";
import Toaster from "../../../../components/reusable/Toaster";
import MyTable from "../../../../components/reusable/table/table-component";
import { Button } from "../../../../components/ui/button";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import FileInput from "./fileInput";
import { useLoading } from "../../../../utils/contexts/LoadingContext";

// icons / images
import { IoAdd } from "react-icons/io5";

const UploadSAFTForm = () => {
  const axiosPrivate = useAxiosPrivate();
  const darkMode = useDarkMode();
  const [showModal, setShowModal] = useState(false);
  const { loading, setLoading } = useLoading();
  const [change, setChange] = useState(false);
  const [fileArray, setFileArray] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [form, setForm] = useState({
    name: "",
    description: "",
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [uploads, setUploads] = useState([]);
  const uploadsList = "upload_list";
  const generateUploadURL = "generate_upload_url/";
  const loadDataURL = "loadSaftData/";

  function formatDataItem(item) {
    const date = new Date(item.zdata);
    const formattedDate = date.toLocaleDateString("pt-PT", {
      hour: "numeric",
      minute: "numeric",
    });

    return {
      ...item,
      zdata: formattedDate,
    };
  }

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getGraph = async (endpoint) => {
      try {
        const url = `${endpoint}`;

        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });

        console.log(response.data);
        const dataFormatted = response.data.map(formatDataItem);
        setUploads(dataFormatted);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    const fetchData = () => {
      getGraph(uploadsList);
    };

    fetchData();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [change]);

  const handleUpload = async () => {
    submitFile();
    //setLoading(true);
    setForm({
      name: "",
      description: "",
    });
  };

  async function submitFile() {
    try {
      //setStatus({ loading: true, error: false });
      setLoading(true);
      if (
        form.name === "" ||
        form.description === "" ||
        fileName.length === 0
      ) {
        Toaster.show("Por favor preencha o campo em falta.", "error");

        throw new Error("blank-form");
      }
      // console.log(fileName[0]);
      // console.log(form.name);
      // console.log(form.description);
      const response = await axiosPrivate.post(
        generateUploadURL,
        JSON.stringify({
          filename: fileName[0],
          name: form.name,
          description: form.description,
        })
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      const result = response.data;
      const uploadUrl = result.upload_url;
      const upload = await axios.put(uploadUrl, fileArray[0], {
        headers: {
          ContentType: "application/xml",
          "x-ms-blob-type": `BlockBlob`,
        },
      });
      console.log("upload status: ", upload.status);
      if (upload.status < 200 || upload.status >= 300) {
        setLoading(false);
        throw new Error(upload.statusText);
      } else {
        const blob_path = result.blob_path;
        const loadData = axiosPrivate.post(
          loadDataURL,
          JSON.stringify({
            blob_path: blob_path,
          })
        );
      }
      //setStatus({ loading: false, error: false });
      setLoading(false);
      setShowModal(false);
      setChange(!change);
      Toaster.show("Ficheiro submetido com sucesso.", "success");
      setIsDialogOpen(false);
      return result;
    } catch (error) {
      switch (error.message) {
        case "blank-form":
          break;
        default:
          //Toaster.show("Ocorreu um erro inesperado. Por favor tente novamente.");
          console.log(error.message);
      }
      //setStatus({ loading: false, error: true });
      setLoading(false);
      if (error instanceof Error) {
        console.log("error message: ", error.message);
        console.log("error stack: ", error.stack);
        return error.message;
      } else {
        console.log("unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  return (
    <div className="w-[80%] mx-auto">
      <div className="flex justify-between">
        <CardHeader>
          <CardTitle>Uploads</CardTitle>
          <CardDescription>
            Faça o upload dos seus ficheiros de vendas (SAFT)
          </CardDescription>
        </CardHeader>
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogTrigger asChild>
            <Button className="mt-5" onClick={() => setIsDialogOpen(true)}>
              <IoAdd size={16} className="mr-2" /> Upload
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Upload SAFT</DialogTitle>
              <DialogDescription>
                Por favor selecione um ficheiro para fazer o upload.
              </DialogDescription>
            </DialogHeader>
            <div className="flex flex-col gap-8">
              <div>
                <Label>Nome da submissão</Label>
                <Input
                  name="name"
                  value={form.name}
                  placeholder="Nome da submissão"
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                />
              </div>
              <div>
                <Label>Descrição da submissão</Label>
                <Input
                  placeholder="Descrição da submissão"
                  name="description"
                  value={form.description}
                  onChange={(e) =>
                    setForm({ ...form, description: e.target.value })
                  }
                />
              </div>
              <div>
                <Label>Arraste ou escolha o ficheiro SAFT</Label>
                <FileInput
                  setFileArray={setFileArray}
                  setFileName={setFileName}
                />
              </div>
            </div>
            <div className="flex justify-end gap-2">
              <Button onClick={handleUpload}>Submeter</Button>
              <DialogClose asChild>
                <Button className="bg-red-500 hover:bg-red-600">
                  Cancelar
                </Button>
              </DialogClose>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <div className="mt-5">
        {!loading && uploads.length === 0 ? (
          <p style={{ color: darkMode ? "white" : "black" }}>
            Os seus uploads aparecerão aqui. Faça um upload agora!
          </p>
        ) : (
          <Table className="w-full">
            <TableHeader>
              <TableRow>
                <TableHead>Utilizador</TableHead>
                <TableHead>Nome</TableHead>
                <TableHead>Descrição</TableHead>
                <TableHead>Data do Upload</TableHead>
                <TableHead>Ficheiro</TableHead>
                <TableHead>Estado</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {uploads.map((upload, index) => (
                <TableRow key={index}>
                  <TableCell>{upload.username}</TableCell>
                  <TableCell>{upload.upload_name}</TableCell>
                  <TableCell>{upload.upload_descr}</TableCell>
                  <TableCell>{upload.zdata}</TableCell>
                  <TableCell>{upload.upload_fn_orig}</TableCell>
                  <TableCell>{upload.state}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <Loading isOpen={loading} />
      </div>
    </div>
  );
};

export default UploadSAFTForm;
