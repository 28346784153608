// React
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import Page from "../../page";
import BackComponent from "../../../../components/reusable/goBack/go-back-component";
import ChartComponent from "../../../../components/reusable/charts/chartComponent";
import Loading from "../../../../components/reusable/loading/loading";

import { useLoading } from "../../../../utils/contexts/LoadingContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";


const EventsView = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const { get } = useApi();

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "Events VIEW PAGE",
  });

  const location = useLocation();
  const { an_id, an_nome, an_descr } = location.state || {};

  const [analysis, setAnalysis] = useState({
    kpiLine: null,
    reportDescrStats: null,
  });
  const { loading, setLoading } = useLoading();
  const chartRef = useRef(null);

  useEffect(() => {

    // Get analysis details
    const getAnalysisDetail = async () => {
      setLoading(true);
      try {
        const [response1, response2] = await Promise.all([
          get(`dash_events_q1_kpi_line/?an_id=${an_id}`, {}),
          get(`dash_events_q1_kpi_report_descr_stats/?an_id=${an_id}`, {})
        ]);
    
        console.log("getAnalysisDetail response1:", response1.data);
        console.log("getAnalysisDetail response2:", response2.data);
    
        setAnalysis({
          kpiLine: response1.data,
          reportDescrStats: response2.data.data
        });
    
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    getAnalysisDetail();
  }, [an_id]);


  const renderAnalysisTable = () => (
    <Table>
      <TableHeader>
        <TableRow>
          {["Nome do Teste", "Pre Evento", "Pós Evento", "Diferença", "Diferença (%)"].map((header) => (
            <TableCell key={header}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {analysis.reportDescrStats.map((item, index) => (
          <TableRow key={index}>
            <TableCell className="text-gray-600">{item.tst_name}</TableCell>
            <TableCell className="text-gray-600">{item.pre_event}</TableCell>
            <TableCell className="text-gray-600">{item.post_event}</TableCell>
            <TableCell className="text-gray-600">{item.dif}</TableCell>
            <TableCell className="text-gray-600">{item.dif_pct}%</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <Page
      loading={loading}
    >
      {tokenVerified ? (
        <div>
          <div
            style={{ paddingBottom: "20px", paddingTop: "10px" }}
            className="padding"
          >
            <BackComponent />
            <div className="bg-white overflow-hidden rounded-lg">
              <div className="md:flex">
                <div className="md:w-1/2 p-8">
                  <div className="uppercase tracking-wide text-xl text-gray-700 font-semibold">
                    {an_nome}
                  </div>
                  <p className="bg-gray-100 mt-2 text-gray-600 p-4 rounded-lg">
                    {an_descr}
                  </p>
                </div>
              </div>
            </div>
            <div className="py-6">
              <ChartComponent
                chartRef={chartRef}
                option={analysis.kpiLine}
                ChangeScale={false}
              />
            </div>
            <div>
              {analysis.reportDescrStats && analysis.reportDescrStats.length > 0 && renderAnalysisTable()}
            </div>
          </div>
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default EventsView;
