import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { PaginationItem } from "@mui/material";
import useDarkMode from "../../../pages/Profile/usedarkmode";

const Paginate = ({ loading, totalPages, currentPage, setCurrentPage }) => {
  const darkMode = useDarkMode();
  return (
    <>
      {!loading && totalPages > 1 && (
        <Stack spacing={2}>
          <Pagination
            color={"secondary"}
            count={totalPages}
            shape="rounded"
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                style={{ color: darkMode ? "white" : "black" }}
              />
            )}
          />
        </Stack>
      )}
    </>
  );
};

export default Paginate;