// React
import { useState } from "react";
import { useLocation } from "react-router-dom";

// Hooks
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";

// Components
import Loading from "../../../../components/reusable/loading/loading";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";

const IngredEditModal = ({
  ingred = null,
  assignId,
  modalCloser,
  setChange,
  change,
  quantity = 0,
  qntType = "g",
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const recipeId = queryParams.get("id");
  const axiosPrivate = useAxiosPrivate();
  const [selectedQant, setSelectedQant] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(qntType); // Default unit is grams
  const [loading, loadingSetter] = useState(false);

  const handleAssign = async () => {
    loadingSetter(true);
    const ingredToAssign = {
      recipe: recipeId,
      ingr_id: ingred.ingr_id,
      ingr_qt: selectedQant,
      assign_id: assignId,
      ingr_qt_unit: selectedUnit, // Provide a value for this field
    };
    try {
      const response = await axiosPrivate.post(
        `/assign_create_or_update/`,
        JSON.stringify(ingredToAssign)
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Ingrediente guardado com sucesso.", "success");
        setChange(!change);
      } else {
        Toaster.show("Erro ao guardar ingrediente.", "error");
        loadingSetter(false);
      }
    } catch (error) {
      Toaster.show("Erro ao guardar ingrediente.", "error");
      loadingSetter(false);
    }
  };
  const handleUnitChange = (newUnit) => {
    setSelectedUnit(newUnit);
  };
  const handleQuantityChange = (newQant) => {
    setSelectedQant(newQant);
  };
  const darkMode = useDarkMode();

  return (
    <>
      <div>
        <div style={{ paddingTop: "20px", paddingBottom: "50px" }}>
          <Table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
            <TableHeader className="bg-blue-dark text-white rounded">
              <TableRow>
                <TableHead
                  style={{ background: darkMode ? "#422655" : "" }}
                  className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                >
                  Nome
                </TableHead>
                <TableHead
                  style={{ background: darkMode ? "#422655" : "" }}
                  className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                >
                  Quantidade
                </TableHead>
                <TableHead
                  style={{ background: darkMode ? "#422655" : "" }}
                  className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                >
                  Unidade
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow key={ingred.ingr_id}>
                <TableCell>
                  {ingred.ingr_name}
                </TableCell>
                <TableCell>
                  <input
                    placeholder={quantity.toString()}
                    style={{ background: "transparent", maxWidth: "100px" }}
                    type="number"
                    min={0}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      handleQuantityChange(value);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <select
                    style={{ background: "transparent", maxWidth: "100px" }}
                    value={selectedUnit}
                    onChange={(e) => handleUnitChange(e.target.value)}
                  >
                    <option value="g">Gramas</option>
                    <option value="ml">Mililitros</option>
                  </select>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div>
          <Button
            type="submit"
            onClick={() => {
              modalCloser(false);
              handleAssign();
            }}
          >
            Guardar Associação
          </Button>
        </div>
      </div>
      <Loading isOpen={loading} />
    </>
  );
};

export default IngredEditModal;
