// React
import React, { useEffect, useState, useCallback } from "react";


// Contexts
import { DarkModeProvider } from "../../../utils/contexts/theme.context";
import useDarkMode from "../../Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import { useLoading } from "../../../utils/contexts/LoadingContext";

// Components
import Page from "../page";
import Loading from "../../../components/reusable/loading/loading";
import MyTable from "../../../components/reusable/table/table-component";
import SelectComponent from "../../../components/reusable/select/select";
import NWeeksFormatter from "../../../components/reusable/select/nweeks";
import Toaster from "../../../components/reusable/Toaster";
import SearchBar from "../../../components/ui/searchbar";
// Icons / Images
import { IoPricetag } from "react-icons/io5";

const INITIAL_WEEKS = 4;

const columns = [
  { headerName: "Receita", field: "recipe_name" },
  { headerName: "Delta", field: "delta_r" },
  { headerName: "Delta pct", field: "delta_pct_r" },
  { headerName: "Média", field: "recipe_price_avg_r" },
  { headerName: "Preço inicial", field: "price_ini_r" },
  { headerName: "Preço final", field: "price_fim_r" },
  { headerName: "Preço máximo", field: "price_max_r" },
  { headerName: "Preço mínimo", field: "price_min_r" },
];

const FoodCostPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "FOODCOST RECIPES TABLE PAGE",
  });

  const { get } = useApi();
  const darkMode = useDarkMode();

  const [recipes, setRecipes] = useState([]);
  const [nweeks, setNweeks] = useState(INITIAL_WEEKS);
  const { loading, setLoading } = useLoading();
  const [search, setSearch] = useState("");
  const [searchItem, setSearchItem] = useState("");

  const fetchRecipes = useCallback(async () => {
    setLoading(true);
    try {
      const url = `dash3_list_recipes/?nweeks=${nweeks}`;
      const response = await get(url);
      setRecipes(response.data);
    } catch (error) {
      Toaster.show("Erro ao carregar dados de receitas", "error");
      console.error("Error fetching recipes:", error);
    } finally {
      setLoading(false);
    }
  }, [nweeks]);

  useEffect(() => {
    fetchRecipes();
  }, [fetchRecipes]);

  const filteredRecipes = recipes.filter((recipe) =>
    recipe.recipe_name.toLowerCase().includes(searchItem.toLowerCase())
  );
  return (
    <Page title={"Drilldown de Receitas"} loading={loading}>
      {tokenVerified ? (
        <DarkModeProvider>
          <div>
            <div>
              <div className="padding">
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <SelectComponent
                    formatter={NWeeksFormatter}
                    darkMode={darkMode}
                    onChange={setNweeks}
                  />
                </div>
              </div>
              <div className="padding">
                <div className="search">
                  <SearchBar
                    search={search}
                    setSearch={setSearch}
                    setFetchSearch={setSearchItem}

                  />
                </div>
                <MyTable
                  rowData={filteredRecipes}
                  columnDefs={columns}
                  link={"/app/foodcost/recipes?item="}
                  linkIndex={0}
                  darkMode={darkMode}
                  title="Receitas"
                />
              </div>
              <Loading isOpen={loading} />
            </div>
          </div>
        </DarkModeProvider>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};
export default FoodCostPage;
